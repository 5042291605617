import { environment } from "./../../environments/environment";
import { DefaultResponse } from "./../interfaces/default.response";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateInterface } from "../shared/date-picker/date-picker.component";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  hasPermission$ = new BehaviorSubject<boolean>(false);
  loginType$ = new BehaviorSubject<"admin" | "la">("admin");
  constructor(private _http: HttpClient) {}

  getAllPartners(query, pageNumber, size?) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/${query}-partners?page=${pageNumber}&per_page=10&size=${size}`
    );
  }

  getPartnerMetrics() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/partner-stats`);
  }

  fetchSinglePartner(id: string) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/partners/${id}`);
  }

  fetchTruckMaker() {
    return this._http.get<DefaultResponse>(`${environment.base_url}truck-makers`);
  }

  fetchTruckCapacity() {
    return this._http.get<DefaultResponse>(`${environment.base_url}capacity`);
  }

  fetchTruckCategory() {
    return this._http.get<DefaultResponse>(`${environment.base_url}category/truck`);
  }
  getAllShipments() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/all-shipments`);
  }

  createTruck(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/partner-trucks`, payload);
  }

  createDriver(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/partner-drivers`,
      payload
    );
  }

  createPartner(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-partner`, payload);
  }
  updatePartner(id, payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/update-partner/${id}`,
      payload
    );
  }

  suspendPartner(user_id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/suspend-partner`, {
      user_id,
    });
  }

  reactivatePartner(user_id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/reactivate-partner`, {
      user_id,
    });
  }

  activatePendingPartner(user_id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/activate-partner`, {
      user_id,
    });
  }

  fetchFilteredTrips(query) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/${query}-trips`);
  }

  getShipmentsMetrics() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/shipment-analysis`);
  }

  approveShipment(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/approve-shipment`,
      payload
    );
  }

  fetchSingleTrip(tripId) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/trips/${tripId}`);
  }

  fetchPendingShipment(query, pageNumber = 1) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/${query}-shipments?page=${pageNumber}`
    );
  }

  fetchSingleShipment(id) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/shipments/${id}`);
  }

  fetchDashboardData() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/dashboard`);
  }

  assignTripToPartner(payload) {
    return this._http.post<DefaultResponse>(
      `
      ${environment.base_url}admin/assign-trip`,
      payload
    );
  }

  fetchDriversByPartner(partner_id: string, pageNumber = 1) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/partner-drivers/${partner_id}?page=${pageNumber}&size=1000`
    );
  }
  fetchTrucksByPartner(partner_id: string, pageNumber = 1) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/partner-trucks/${partner_id}?page=${pageNumber}&size=1000`
    );
  }

  cancelAssignedTrip(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/cancel-trip`, payload);
  }

  searchPartner(pageNumber = 1, query) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/search-partners/${query}`);
  }

  fetchEnrouteStatus() {
    return this._http.get<DefaultResponse>(`${environment.base_url}enroute-status`);
  }

  fetchAllPayments(pageNumber: number) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/transactions?page=${pageNumber}`
    );
  }

  fetchPartnerPayments(query) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/${query}_payment`);
  }

  fetchClientPayments() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/client-payments`);
  }

  fetchSinglePayment(id) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/transactions/${id}`);
  }

  fetchPaymentStatus() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/payment_status`);
  }

  initiatePayment(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/initiate_payment`,
      payload
    );
  }

  uploadInvoice(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-invoice`, payload);
  }

  confirmClientPayment(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/transactions/update-client-payment `,
      payload
    );
  }
  approvePayment(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/approve_payment`,
      payload
    );
  }

  declinePayment(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/decline_payment`,
      payload
    );
  }

  updatePaymentPrice(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/transactions/update-partner-payment`,
      payload
    );
  }

  fetchClients(query: string, pageNumber: number, size?) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/${query}-clients?page=${pageNumber}&size=${size}`
    );
  }

  fetchSingleClient(id) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/clients/${id}`);
  }

  updateClient(id, payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/update-client/${id}`,
      payload
    );
  }

  createClient(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-client`, payload);
  }

  deleteClient(user_id) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/delete-client?user_id=${user_id}`,
      {
        user_id,
      }
    );
  }

  getClientMetrics() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/client-stats`);
  }

  suspendClient(user_id) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/suspend-client?user_id=${user_id}`,
      {
        user_id,
      }
    );
  }

  reactivateClient(user_id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/reactivate-client`, {
      user_id,
    });
  }

  createWaybill(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-waybill`, payload);
  }
  updateWaybill(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/update-waybill`, payload);
  }

  createPod(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-proof`, payload);
  }
  updatePod(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/update-proof`, payload);
  }

  updateTripLocation(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/update-trip-enroute`,
      payload
    );
  }

  fetchTripStatus() {
    return this._http.get<DefaultResponse>(`${environment.base_url}trip-status`);
  }

  startTrip(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/set-trip-enroute`,
      payload
    );
  }

  arrivedDestination(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/trigger-arrived-destination`,
      payload
    );
  }
  arrivedPickupLocation(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/trigger-truck-arrived-pickup-location`,
      payload
    );
  }

  goodsOnboarded(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/trigger-goods-onboarded-truck`,
      payload
    );
  }

  manualTripUpdate(payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/trigger-manual-trip-update`,
      payload
    );
  }

  createAdminUser(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/create-admin`, payload);
  }
  getAdminUsers(pageNumber: number, size?: number) {
    return this._http.get<DefaultResponse>(
      `${environment.base_url}admin/admins?page=${pageNumber}&per_page=${size}`
    );
  }
  getAdminUser(id) {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/admins/${id}`);
  }

  suspendAdminUser(id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/suspend-admin`, id);
  }

  activateAdminUser(id) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/activate-admin`, id);
  }
  deleteAdminUser(adminId) {
    return this._http.delete<DefaultResponse>(
      `${environment.base_url}admin/delete-admin/${adminId}`
    );
  }

  getListOfContactMessage() {
    return this._http.get<DefaultResponse>(`${environment.base_url}admin/contacts`);
  }

  getAllPaymentForExport(range: DateInterface) {
    let requestUrl = `admin/export-transactions`;
    if (range.from) {
      requestUrl = `${requestUrl}?from=${range.from}&to=${range.to}&all=false`;
    } else {
      requestUrl = `admin/export-transactions?all=true`;
    }
    return this._http.get(`${environment.base_url}${requestUrl}`, {
      responseType: "blob" as "json",
    });
  }
  getAllClientsForExport(range: DateInterface) {
    let requestUrl = `admin/export-clients`;
    if (range.from) {
      requestUrl = `${requestUrl}?from=${range.from}&to=${range.to}&all=false`;
    } else {
      requestUrl = `admin/export-clients?all=true`;
    }
    return this._http.get(`${environment.base_url}${requestUrl}`, {
      responseType: "blob" as "json",
    });
  }
  getAllShipmentsForExport(range: DateInterface) {
    let requestUrl = `admin/export-shipments`;
    if (range.from) {
      requestUrl = `${requestUrl}?from=${range.from}&to=${range.to}&all=false`;
    } else {
      requestUrl = `admin/export-shipments?all=true`;
    }
    return this._http.get(`${environment.base_url}${requestUrl}`, {
      responseType: "blob" as "json",
    });
  }

  checkPlateNumber(payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/validate-truck`, payload);
  }

  bulkUpload (payload) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/da-bulk-upload`, payload);
  }
}
